import React, { useContext, useState, useEffect } from 'react';
import ThemeContext from '../../context/ThemeContext';
import swal from 'sweetalert';
import './index.css';
import { Auth } from "aws-amplify";

const ProjectCard = ({ projectData }) => {
  const { isDarkTheme } = useContext(ThemeContext);
  const [status, setStatus] = useState({});
  const [snackbarContent, setSnackbarContent] = useState("Processing Request..");
  const [inputValues, setInputValues] = useState({});

  const handleInputChange = (id, value) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [id]: value,
    }));
  };

  function loadSnackbar(msg){
      setSnackbarContent(msg);
      var x = document.getElementById("snackbar");
      x.classList.add("show-snackbar");
      setTimeout(function(){ x.classList.remove("show-snackbar"); }, 5000);
  }

  function hideSnackBar() {
    var x = document.getElementById("snackbar");
    x.classList.remove("show-snackbar");
  }

  const handleStatus = (id, value) => {
    setStatus((prevInputValues) => ({
      ...prevInputValues,
      [id]: value,
    }));
  };

  const parsedProjectData = JSON.parse(projectData);
  const description = parsedProjectData.description;
  const projectName = parsedProjectData.projectName;
  const components = parsedProjectData.components;

  useEffect(() => {
    for (let i=0; i<components.length; i++) {
      if (components[i].component === "status") {
        fetchStatus(components[i].url, components[i].id, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderComponents = () => {
    return components.map((item, index) => {

      if (item.component === 'textbox') {
        return (
          <div className='card-textbox-container'>
                {!isDarkTheme ? (
                  <label className='card-textbox-label'>{item.label}</label>
                ) : (
                  <label className='card-textbox-label dark-font'>{item.label}</label>
                )}
                
                <input
                  className="idm-textfield card-textbox"
                  type={item.inputType}
                  placeholder={item.hint}
                  value={inputValues[item.id] || ''}
                  onChange={(e) => handleInputChange(item.id, e.target.value)}
                />
          </div>
        )
      } 

      else if (item.component === 'status') {
        // fetchStatus(item.url, item.id);
        let s = status[item.id] || 'unknown'
        console.log("s is : ", s);
        return (
          <div className='card-textbox-container'>
                {!isDarkTheme ? (
                  <>
                    <label className='card-textbox-label'>{item.name} : </label>
                    <div id="snackbar" class="idm-snackbar">
                      <p>{snackbarContent}</p>
                      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    </div>
                    </>
                ) : (
                  <>
                    <label className='card-textbox-label dark-font'>{item.name} : </label>
                    <div id="snackbar" class="idm-snackbar">
                      <p>{snackbarContent}</p>
                      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    </div>
                  </>
                )}
                {s === "true"? (
                  <>
                    <label class="idm-tag-default-gray idm-tag-default-green">{s}</label>
                    <img src='./images/refresh.svg' alt='refresh' className='refresh-status' onClick={() => fetchStatus(item.url, item.id, true)}/>
                  </>
                    ) : (
                      <>
                        <label class="idm-tag-default-gray idm-tag-default-orange">{s}</label>
                        <img src='./images/refresh.svg' alt='refresh' className='refresh-status' onClick={() => fetchStatus(item.url, item.id, true)}/>
                      </>
                    )}

          </div>
        )
      }
      
      else if (item.component === 'button') {
        let clickAction = item.clickAction;
        let method = item.method;
        let url = item.url;
        let headers = item.headers;
        let body = item.body;
        return (
          <button className="idm-btn idm-btn-secondary card-btn" 
            onClick={() => handleButtonClick(clickAction, method, url, headers, body)}
          >
            {item.name}
          </button>
        );
      }
      return null;
    });
  };

  let process_body = (body) => {
    const replacements = generateReplacementObject();
    console.log(replacements)
    const processedBody = JSON.stringify(body).replace(/\${(.*?)}/g, (match, key) => replacements[key]);    
    
    return processedBody;
  }

  let generateReplacementObject = () => {
    let replacementObj = {}
    for (var i=0; i<components.length; i++) {
      let component = components[i];
      if ("id" in component) {
        replacementObj[component.id] = inputValues[component.id];
      }
    }
    return replacementObj;
  }

const handleButtonClick = async (clickAction, method, url, headers, body) => {
    if (clickAction === "api") {
        try {
          if (headers === undefined) {
            headers = {}
          }
          if (body === undefined) {
            body = {}
          }

          const requestOptions = {
              method: method,
              headers: headers
          };
          
          if (method === "post") {
            requestOptions.body = process_body(body);
          }

          swal("Processing Request..", {
            buttons: false,
            closeOnClickOutside: false,
            closeOnEsc: false
          });

          // Adding auth header
          Auth.currentSession().then((res, err) => {
            let idToken = res.getIdToken().getJwtToken()
            requestOptions.headers['Authorization'] = idToken
            console.log(requestOptions)
            // Make the API call using node-fetch
            fetch(url, requestOptions).then((response) => response.json())
            .then((data) => {
              swal({
                title: "Success",
                text: "Request was successful!",
                icon: "success",
              })
              console.log('API Response:', data);
            }).catch((exception) => {
              swal({
                title: "Failure",
                text: `${exception}`,
                icon: "warning"
              })
            });
          })
        
        } catch (error) {
            swal({
              title: "Failure",
              text: `${error}`,
              icon: "warning",
              dangerMode: true,
            })
            console.error('API Error:', error);
        }
    }
  };

  const fetchStatus = async (url, id, isManualRefresh) => {
    try {
      if (isManualRefresh) {
        loadSnackbar("Processing Request...");
      }
      var idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
      let requestOptions = {
        method : 'get',
        headers : {
          'Authorization' : idToken
        }
      }
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      // setStatus(data.status);
      handleStatus(id, data.status)
      if (isManualRefresh) {
        hideSnackBar();
        loadSnackbar("Status updated successfully")
      }
    } catch (error) {
      console.error('Error:', error);
      if (isManualRefresh) {
        loadSnackbar(`Failed to update status : ${error}`)
      }
    }
  };

  return (
    <div className="project-container">
    {!isDarkTheme ? (
      <div className="idm-simple_card card-container">
        <h1 className="card-heading">{projectName}</h1>
        <p className="card-description">{description}</p>
        {renderComponents()}
      </div>
    ) : (
      <div className="idm-simple_card card-container-dark">
        <h1 className="card-heading dark-font">{projectName}</h1>
        <p className="card-description dark-font">{description}</p>
        {renderComponents()}
      </div>
    )}
    </div>
  );
};

export default ProjectCard;
