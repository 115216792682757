import { Routes, Route } from 'react-router-dom';
import { Component } from 'react'
import Home from './components/Home'
import About from './components/About'
import ThemeContext from './context/ThemeContext'
import {awsAuthConfiguration, parseJwt, getUserId, signOutUser} from './aws_settings';
import './App.css'

class App extends Component {
  state = { isDarkTheme: false }

  toggleTheme = () =>
    this.setState(prevState => ({ isDarkTheme: !prevState.isDarkTheme }))

  render() {
    const { isDarkTheme } = this.state

    return (
      <ThemeContext.Provider
        value={{ isDarkTheme, toggleTheme: this.toggleTheme }}
      >
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/logout/' element={
            <div>
              <p>You are logged out</p>
            </div>
          } />
        </Routes>
      </ThemeContext.Provider>
    )
  }
}

export default App
