import { Auth } from "aws-amplify";

// replace the user pool region, id, and app client id details
export const AWS_CONFIG = {
    "AWS_REGION" : "eu-west-1",
    "POOL_ID" : "eu-west-1_LuTtpJlbE",
    "POOL_CLIENT_ID" : "4fgk4r60g42ephv4h92jn9tp2u",
    "AUTH_DOMAIN" : "auth.dev.innolab.xantav.com",
    "SIGN_IN_REDIRECT" : "https://demo.dev.innolab.xantav.com",
    "SIGN_OUT_REDIRECT" : "https://demo.dev.innolab.xantav.com/logout/",
    // "DOMAIN_URL" : "https://ts2zanozob.execute-api.eu-west-1.amazonaws.com/v1",
    "DOMAIN_URL" : "http://localhost:8080/dosth"

}

export function awsAuthConfiguration(){

  let awsAuthConfig = {
      Auth: {
        // REQUIRED - Amazon Cognito Region
        region: AWS_CONFIG.AWS_REGION,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: AWS_CONFIG.AWS_REGION,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: AWS_CONFIG.POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: AWS_CONFIG.POOL_CLIENT_ID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'

        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: AWS_CONFIG.AUTH_DOMAIN,
            scope: ['openid'], //email
            redirectSignIn: AWS_CONFIG.SIGN_IN_REDIRECT,
            redirectSignOut: AWS_CONFIG.SIGN_OUT_REDIRECT,
            redirectUri: AWS_CONFIG.SIGN_IN_REDIRECT,
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
      }
  };
  return awsAuthConfig;
}


export function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export async function getUserId(){
  var idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  var userId = parseJwt(idToken).identities[0].userId;
  return userId;
}

export async function signOutUser(){
  Auth.signOut().then(res => {
    return res;
  });
}
