import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
// import {aws_amplify} from './aws_modules/dist/aws-amplify.min'; 
//aws_amplify from './aws_modules/dist/aws-amplify.min';
import { Amplify, Auth } from "aws-amplify";
import {awsAuthConfiguration, parseJwt, getUserId, signOutUser} from './aws_settings';

await initCognitoSDK();

// console.log(await signOutUser());

// Operations when the web page is loaded.
// Initialize a cognito auth object.
async function initCognitoSDK() {
  //configure aws_amplify
  let awsConfig = awsAuthConfiguration();
  Amplify.configure(awsConfig);

  // try {
  //   var idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  //   console.log("Token: " + idToken);
  //   if(idToken !== null || idToken !== ""){
  //     console.log("inside if")
  //     var userId = parseJwt(idToken).identities[0].userId;
  //     console.log("UserSignedIn: " + userId);

  //     // console.log(aws_amplify.Amplify.Auth._OAuthHandler());
  //     showSignedIn(userId);
  //   } else {
  //     Auth.federatedSignIn({provider: "IDEMIA"});
  //   }
  // } catch(err){
  //   console.log(err);
  // //  Auth.federatedSignIn({provider: "IDEMIA"});
  // }

  try {
    var idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    );
  } catch (err) {
    console.log(err)
    Auth.federatedSignIn({customProvider: "IDEMIA"})
  }
}

// Operations when signed in.
function showSignedIn(userId) {
  // Get the element with id="defaultOpen" and click on it
  // document.getElementById("defaultOpen").click();
  // document.getElementById("userLoginId").innerHTML = userId;
}

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
