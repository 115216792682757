import React, { useContext } from 'react';
import ThemeContext from '../../context/ThemeContext'
import { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import ProjectCard from '../ProjectCard';
import './index.css'

const Home = () => {
  const { isDarkTheme } = useContext(ThemeContext);
  const [projects, setProjects] = useState([]);  

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('serviceSchema.json');
      const data = await response.json();
      setProjects(Object.values(data));
    };
    fetchData();

  }, []);

  return (
    <div className="main-home-container">
      <Navbar />
      {!isDarkTheme ? (
        <div className="home-container-light">
            {projects.map((value, index) => (
              <ProjectCard key={index} projectData={JSON.stringify(value)} />
            ))}
        </div>
      ) : (
        <div className="home-container-dark">
          {projects.map((value, index) => (
              <ProjectCard key={index} projectData={JSON.stringify(value)} />
            ))}
        </div>
      )}
    </div>
  );
};

export default Home;
